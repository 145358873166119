import styled from 'styled-components'
import {Fragment} from "react";

const Container = styled.div`
  height: 50px;
  text-align: left;
  margin-top: 30px;
`

const Footer = () => {
    return (
        <footer>
            <Container>
                <hr/>
                КИП-Юг © 2013-2023
            </Container>
        </footer>
    );
}

export default Footer;
