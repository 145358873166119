import {Fragment} from "react";
import styled from "styled-components";
import {Category, Container, VendorLink} from "./style";


export const VendorOwen = () => {
    return (
        <Fragment> С полным перечнем оборудования, техническими характеристиками и актуальными
            ценами Вы можете окзакомиться на оффициальном сайте
            производителя <VendorLink href="https://owen.ru/" target="_blank">owen.ru</VendorLink>
            <Container>
                <Category text={<Fragment>Измерители-регуляторы</Fragment>} alt={'Измерители-регуляторы'} maxh={150}
                   link={'https://owen.ru/catalog/izmeriteli_regulyatori'} src_img={'/owen/regulators.png'}/>

                <Category text={<Fragment>Для ГВС, отопления,<br/>вентиляции и котельных</Fragment>} alt={'Для ГВС, отопления'} maxh={150}
                          link={'https://owen.ru/catalog/kontrolleri_dlya_sistem_ventilyatcii_otopleniya_i_gvs'} src_img={'/owen/gvs.png'}/>

                <Category text={<Fragment>Контроль уровня</Fragment>} alt={'Контроль уровня'} maxh={150}
                          link={'https://owen.ru/catalog/dlya_upravleniya_nasosami'} src_img={'/owen/level.png'}/>

                <Category text={<Fragment>ПЛК, панели оператора,<br/>программируемые реле</Fragment>} alt={'ПЛК, панели оператора'} maxh={150}
                          link={'https://owen.ru/catalog/plc'} src_img={'/owen/plc.png'}/>

                <Category text={<Fragment>Модули<br/>ввода-вывода</Fragment>} alt={'Модули ввода-вывода'} maxh={150}
                          link={'https://owen.ru/catalog/moduli_vvoda_vivoda'} src_img={'/owen/modules.png'}/>

                <Category text={<Fragment>Датчики<br/>температуры</Fragment>} alt={'Датчики температуры'} maxh={150}
                          link={'https://owen.ru/catalog/datchiki_temperaturi'} src_img={'/owen/temperature_sensors.png'}/>

                <Category text={<Fragment>Датчики<br/>давления</Fragment>} alt={'Датчики давления'} maxh={150}
                          link={'https://owen.ru/catalog/datchiki_preobrazovateli_davleniya'} src_img={'/owen/pressure_sensors.png'}/>

                <Category text={<Fragment>Устройства управления<br/>и сигнализации</Fragment>} alt={'Устройства управления'} maxh={150}
                          link={'https://meyertec.owen.ru/'} src_img={'/owen/mayertec.png'}/>

                <Category text={<Fragment>OwenCloud - облачный<br/>сервис диспетчеризации</Fragment>} alt={'OwenCloud'} maxh={150}
                          link={'https://owen.ru/owencloud'} src_img={'/owen/owencloud.png'}/>

                <Category text={<Fragment>И многое другое</Fragment>} alt={'И многое другое'} maxh={150}
                          link={'https://owen.ru/catalog'} src_img={'/owen/other.png'}/>
            </Container>
        </Fragment>
    )
}