import styled from 'styled-components'
import {Fragment} from "react";
import {device} from "../media";

const Logo = styled.img`
  max-height: 130px;
  @media ${device.laptop} {
    max-height: 100px;
  }
  @media ${device.mobile} {
    max-height: 125px;
  }
`
const HeaderContainer = styled.div`
  font-weight: 600;
  a {
    font-weight: 600;
  }
  //height: 150px;
  display: grid;
  justify-items: center;
  align-items: center;
  @media ${device.mobile} {
    grid-template-rows: 1fr 1fr;
    font-size: 20px;
  }

  @media ${device.laptop} {
    grid-template-columns: 1fr 1fr;
    font-size: 17px;
    a {
      font-size: 17px;
    }
  }
  
  @media ${device.desktop} {
    grid-template-columns: 1fr 1fr;
  }
  
  > div:nth-child(3) {
    
  }
`

const ContactsContainer = styled.div`
  p {
    margin: 10px 0 10px 0 ;
    
  }
  @media ${device.mobile} {
    letter-spacing: -0.5px;
  }
`

const Header = () => {
    return (
        <Fragment>
        <HeaderContainer>
            <div>
                <Logo src={'/logo.png'} alt={'Лого'}/>
            </div>

            <ContactsContainer>
                <div>
                    <p>
                        <nobr>г.Ставрополь, 3-я Промышленная, 6</nobr>
                    </p>
                    <p>
                        <nobr>info@kip-ug.com</nobr>
                    </p>
                </div>
                <div>
                    <p>
                        <a href={'tel:+79624421225'}>
                            <nobr>
                                +7 (9624) 42-12-25
                            </nobr>
                        </a>
                    </p>
                </div>
            </ContactsContainer>

        </HeaderContainer>
            <hr/>
        </Fragment>
    );
}

export default Header;
