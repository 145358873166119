const size = {
    desktop: '900px',
    laptop: '900px',
    mobile: '600px',
}

export const device = {
    desktop: `(min-width: ${size.desktop})`,
    laptop: `(min-width: ${size.mobile}) and (max-width: ${size.desktop})`,
    mobile: `(max-width: ${size.mobile})`,
};