import styled from 'styled-components'
import {Fragment} from "react";
import {GlobalStyle} from "./app-styled";
import Header from "../header/header";
import Footer from "../footer/footer";
import Menu from "../menu/menu";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Catalog from "../catalog/catalog";
import Delivery from "../menu/delivery";
import Contacts from "../menu/contacts";
import Services from "../menu/services";

const App = () => {
    return (
        <Fragment>
            <GlobalStyle/>
            <BrowserRouter>
                <Header/>
                <Menu/>
                <Routes>
                    <Route path="/" element={<Navigate to="/catalog"/>}/>
                    <Route path="/catalog/*" element={<Catalog/>}/>
                    <Route path="/services" element={<Services/>}/>
                    <Route path="/delivery" element={<Delivery/>}/>
                    <Route path="/contacts" element={<Contacts/>}/>
                    <Route path="*" element={<Navigate to="/catalog"/>}/>
                </Routes>
                <Footer/>
            </BrowserRouter>
        </Fragment>
    );
}

export default App;
