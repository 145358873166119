import {Fragment} from "react";
import styled from "styled-components";


const Table = styled.table`
  border-collapse: collapse;
  td {
    border: 1px solid rgb(234, 234, 234);
    padding: 5px 15px 5px 15px;
  }


  tbody:hover td[rowspan],
  tr:hover td {
    background: rgba(246, 246, 246, 0.5);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
`
const ImgContainer = styled.div`
  display: flex;
  //flex-direction: row;
  justify-content: space-around;
  margin-bottom: 20px;
`
const Img = styled.img`
  max-height: 200px;
`;

export const VendorMeanwell = () => {
    return (
        <Container>
            <h2>Блоки питания Meanwell</h2>
            <ImgContainer>
                <div>
                    <div>Серия HDR</div>
                    <Img src='/meanwell/HDR.jpg' alt='HDR'/>
                </div>
                <div>
                    <div>Серия MDR</div>
                    <Img src='/meanwell/MDR.jpg' alt='MDR'/>
                </div>
            </ImgContainer>
            <Table>
                <tbody>
                <tr>
                    <td>Наименование</td>
                    <td>Мощность</td>
                    <td>Выходное<br/>напряжение</td>
                    <td>Выходной<br/>ток</td>
                    <td>Цена, с НДС</td>
                </tr>
                </tbody>
                <tbody>
                <tr>
                    <td>HDR-15-24</td>
                    <td>15 Вт</td>
                    <td rowSpan={5}>24 В</td>
                    <td>0,63 А</td>
                    <td>1500,00 руб.</td>
                </tr>
                <tr>
                    <td>HDR-30-24</td>
                    <td>36 Вт</td>
                    <td>1,5 А</td>
                    <td>1500,00 руб.</td>
                </tr>
                <tr>
                    <td>HDR-60-24 <br/> MDR-60-24</td>
                    <td>60 Вт</td>
                    <td>2,5 А</td>
                    <td>2000,00 руб.</td>
                </tr>
                <tr>
                    <td>HDR-100-24</td>
                    <td>92 Вт</td>
                    <td>3,83 А</td>
                    <td>2500,00 руб.</td>
                </tr>
                <tr>
                    <td>HDR-150-24</td>
                    <td>150 Вт</td>
                    <td>6,25 А</td>
                    <td>3500,00 руб.</td>
                </tr>
                </tbody>

                <tbody>
                <tr>
                    <td>HDR-15-12</td>
                    <td>15 Вт</td>
                    <td rowSpan={3}>12 В</td>
                    <td>1,25 А</td>
                    <td>1500,00 руб.</td>
                </tr>
                <tr>
                    <td>HDR-30-12</td>
                    <td>24 Вт</td>
                    <td>2 А</td>
                    <td>1500,00 руб.</td>
                </tr>
                <tr>
                    <td>HDR-60-12</td>
                    <td>54 Вт</td>
                    <td>4,5 А</td>
                    <td>2000,00 руб.</td>
                </tr>
                </tbody>
            </Table>
        </Container>
    )
}