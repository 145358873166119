import { createGlobalStyle } from 'styled-components'
import KIPNorms from '../fonts/KIPNorms.woff'
import KIPNorms2 from '../fonts/KIPNorms.woff2'

export const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: 'TT Norms Regular';
    src: url(${KIPNorms2}) format('woff2'),
    url(${KIPNorms}) format('woff');
  };
    
    a, a:active {
      text-decoration:none;
    }
    
    body, a {
      font-size: 20px;
      font-weight: 400;
      color: rgb(15, 74, 87);
      font-family: "TT Norms Regular", serif;
      text-align: center;
    }
    body {
      max-width: 1200px;
      margin: auto;
      padding-top: 10px;
      padding-right: 10px;
      padding-left: 10px;
      //padding-bottom: 40px;
      overflow-y: scroll;
    }
    
`;