import styled from "styled-components";
import {Fragment} from "react";


export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 25px;
`

const Logo = styled.img`
  max-height: ${props => props.maxh}px;
  margin-top: 5px;
`;

const CategoryContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border: 1px solid transparent;
  &:hover {
    //background: rgba(246, 246, 246, 0.5);
    border: 1px solid rgb(234, 234, 234);
  } 
  
`

const Category = ({src_img, maxh, text, alt}) => {
    return (
        <CategoryContainer>
            <div>
                <div>{text}</div>
                <Logo src={src_img} alt={alt} maxh={maxh}/>
            </div>
        </CategoryContainer>
    )
}


const Services = () => {
    return (
        <Container>
            <Category text={<Fragment>Сборка щитов управления</Fragment>} alt={'Сборка щитов управления'} maxh={250}
                      src_img={'/services/box.jpg'}/>

            <Category text={<Fragment>Программирование ПЛК, <br/> панелей оператора</Fragment>} alt={'Программирование ПЛК'} maxh={250}
                      src_img={'/services/prog.jpg'}/>

            <Category text={<Fragment>Разработка схем<br/>и проектов</Fragment>} alt={'Разработка схем'} maxh={250}
                     src_img={'/services/scheme.jpg'}/>

        </Container>
    );
}

export default Services;