import styled from "styled-components";

const Container = styled.div`
  text-align: left;
`

const Contacts = () => {
    return (
        <Container>
            <div>
                Режим работы: пн-пт с 9:00 до 17:00
            </div>
            <img src='/map.jpg' alt='карта'/>
        </Container>
    );
}

export default Contacts;