import styled from "styled-components";

export const VendorLink = styled.a`
  text-decoration: underline;
  color: red;
`

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 25px;
`

const Logo = styled.img`
  max-height: ${props => props.maxh}px;
  //max-height: 150px;
`;

const CategoryContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border: 1px solid transparent;
  &:hover {
    //background: rgba(246, 246, 246, 0.5);
    border: 1px solid rgb(234, 234, 234);
  } 
  
`

export const Category = ({link, src_img, maxh, text, alt}) => {
    return (
        <CategoryContainer>
            <a href={link} target="_blank">
                <div>{text}</div>
                <Logo src={src_img} alt={alt} maxh={maxh}/>
            </a>
        </CategoryContainer>
    )
}