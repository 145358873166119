import styled from "styled-components";

const Container = styled.div `
  text-align: left;
  li {
    margin-top: 10px;
  }
`

const Delivery = () => {
    return (
        <Container>
            <ul>
                <li>ТК Деловые Линии, СДЭК - возим грузы сами до терминала 2 раза в неделю</li>
                <li>Любой другой транспортной компанией с услугой забора груза</li>
                <li>Автобусом - 300 руб. за отправку (доставка оплачивается отдельно при получении)</li>
            </ul>
        </Container>
    );
}

export default Delivery;