import {Fragment} from "react";
import styled from "styled-components";
import {Category, Container, VendorLink} from "./style";


export const VendorRosma = () => {
    return (
        <Fragment> С полным перечнем оборудования, техническими характеристиками и актуальными
            ценами Вы можете окзакомиться на оффициальном сайте
            производителя <VendorLink href="https://rosma.spb.ru/" target="_blank">rosma.spb.ru</VendorLink>
            <Container>
                <Category text={<Fragment>Манометры, ЭКМ</Fragment>} alt={'Манометры, ЭКМ'} maxh={150}
                   link={'https://rosma.spb.ru/manometers/'} src_img={'/rosma/manometers.jpg'}/>

                <Category text={<Fragment>Термометры</Fragment>} alt={'Термометры'} maxh={150}
                          link={'https://rosma.spb.ru/termometers/'} src_img={'/rosma/termometers.jpg'}/>

                <Category text={<Fragment>Электромагнитные <br/> клапаны</Fragment>} alt={'Электромагнитные клапаны'} maxh={150}
                          link={'https://rosma.spb.ru/klapany_ehlektromagnitnye_solenoidnye/'} src_img={'/rosma/valves.jpg'}/>

                <Category text={<Fragment>Реле потока</Fragment>} alt={'Реле потока'} maxh={150}
                          link={'https://rosma.spb.ru/rele_davleniya/rp-1-rele-potoka/'} src_img={'/rosma/rp.jpg'}/>

                <Category text={<Fragment>Реле давления</Fragment>} alt={'Реле давления'} maxh={150}
                          link={'https://rosma.spb.ru/rele_davleniya/'} src_img={'/rosma/rd.jpg'}/>

                <Category text={<Fragment>Трёхходовые<br/> краны</Fragment>} alt={'Трёхходовые краны'} maxh={150}
                          link={'https://rosma.spb.ru/krany-i-klapany/'} src_img={'/rosma/3valves.jpg'}/>

                <Category text={<Fragment>Аксессуары</Fragment>} alt={'Аксессуары'} maxh={150}
                          link={'https://rosma.spb.ru/dop_oborudovanie/'} src_img={'/rosma/accessories.jpg'}/>
            </Container>
        </Fragment>
    )
}