import {Fragment} from "react";
import styled from "styled-components";
import {Navigate, NavLink, Route, Routes} from "react-router-dom";
import {device} from "../media";
import {VendorOwen} from "./vendors/owen";
import {VendorRosma} from "./vendors/rosma";
import {VendorKippribor} from "./vendors/kippribor";
import {VendorMeanwell} from "./vendors/meanwell";
import {VendorNovatek} from "./vendors/novatek";
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

const LinkContainer = styled.div`
  margin-top: 15px;
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
`

const Logo = styled.img`
  max-height:  ${props => props.maxh}px;
  max-width: 300px;
  `;

const StyledLink = styled(NavLink)`
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  padding: 5px;
  //margin: 0 10px 0 10px;
  margin: 10px;
  box-sizing: border-box;
  border: 1px solid rgb(234, 234, 234);
  -moz-box-sizing: border-box; /* Для Firefox */
  -webkit-box-sizing: border-box; /* Для Safari и Chrome */

  :hover {
    background: rgba(246, 246, 246, 0.2);
    border-color: rgb(15, 74, 87);
  }

  &.active {
    //background: #d51a1a;
    border-color: rgb(15, 74, 87);
  }
`;

const Vendor = ({link, src_img, maxh}) => {
    return (
        <StyledLink to={link} onClick={() => scrollTo()}>
            <div>
                <Logo src={src_img} alt={link} maxh={maxh}/>
            </div>
        </StyledLink>
    )
}

const scrollTo= () => {
    scroller.scrollTo('scroll-to-element', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
    })
}

const Catalog = () => {
    return (
        <Fragment>
            <LinkContainer>
                <Vendor link={'owen'} src_img={'/menu_owen.png'} maxh={90} />
                <Vendor link={'rosma'} src_img={'/menu_rosma.png'} maxh={150} />
                <Vendor link={'kippribor'} src_img={'/menu_kippribor.png'} maxh={70} />
                <Vendor link={'meanwell'} src_img={'/menu_meanwell.png'} maxh={120} />
                <Vendor link={'novatek'} src_img={'/menu_novatek.png'} maxh={120} />
            </LinkContainer>
            <hr/>
            <Element name="scroll-to-element"/>
            <Routes>
                <Route path="/" element={<Navigate to="owen"/>} />
                <Route path="owen" element={<VendorOwen/>}/>
                <Route path="rosma" element={<VendorRosma/>}/>
                <Route path="kippribor" element={<VendorKippribor/>}/>
                <Route path="meanwell" element={<VendorMeanwell/>}/>
                <Route path="novatek" element={<VendorNovatek/>}/>
            </Routes>

        </Fragment>

    )
}

export default Catalog