import {Fragment} from "react";
import styled from "styled-components";
import {Category, Container, VendorLink} from "./style";


export const VendorNovatek = () => {
    return (
        <Fragment> С полным перечнем оборудования, техническими характеристиками и актуальными
            ценами Вы можете окзакомиться на оффициальном сайте
            производителя <VendorLink href="https://novatek-electro.ru/catalog/" target="_blank">novatek-electro.ru</VendorLink>
            <Container>
                <Category text={<Fragment>Реле времени</Fragment>} alt={'Реле времени'} maxh={150}
                   link={'https://novatek-electro.ru/catalog/mnogofunktsionalnye-rele-vremeni/'} src_img={'/novatek/rev.jpg'}/>

                <Category text={<Fragment>Реле напряжения <br/> и контроля фаз</Fragment>} alt={'Реле напряжения'} maxh={150}
                          link={'https://novatek-electro.ru/catalog/trekhfaznye-rele-napryazheniya/'} src_img={'/novatek/rnpp.jpg'}/>


                <Category text={<Fragment>Токовые реле</Fragment>} alt={'Токовые реле'} maxh={150}
                          link={'https://novatek-electro.ru/catalog/tokovye-rele-i-ogranichiteli-moshchnosti/'} src_img={'/novatek/rt.jpg'}/>


            </Container>
        </Fragment>
    )
}