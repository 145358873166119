import {Fragment} from "react";
import styled from "styled-components";
import {Category, Container, VendorLink} from "./style";


export const VendorKippribor = () => {
    return (
        <Fragment> С полным перечнем оборудования, техническими характеристиками и актуальными
            ценами Вы можете окзакомиться на оффициальном сайте
            производителя <VendorLink href="https://www.kippribor.ru/" target="_blank">kippribor.ru</VendorLink>
            <Container>
                <Category text={<Fragment>Промежуточные реле <br/> и колодки</Fragment>} alt={'Промежуточные реле'} maxh={150}
                   link={'https://www.kippribor.ru/Promezhutochnye-rele-i-kolodki'} src_img={'/kippribor/rele.png'}/>

                <Category text={<Fragment>Твердотельные реле <br/> и радиаторы</Fragment>} alt={'Твердотельные реле'} maxh={150}
                          link={'https://www.kippribor.ru/TTR-i-radiatory'} src_img={'/kippribor/ttr.png'}/>

                <Category text={<Fragment>Преобразователи <br/> частоты</Fragment>} alt={'Преобразователи частоты'} maxh={150}
                          link={'https://www.kippribor.ru/KIPPRIBOR_AFD'} src_img={'/kippribor/pch.png'}/>

                <Category text={<Fragment>Микроклимат щитов<br/>управления </Fragment>} alt={'Микроклимат щитов'} maxh={150}
                          link={'https://www.kippribor.ru/?id=1051'} src_img={'/kippribor/vent.jpg'}/>


            </Container>
        </Fragment>
    )
}