import {Fragment} from "react";
import styled from "styled-components";
import {device} from "../media";
import {NavLink} from "react-router-dom";

const MenuContainer = styled.div`
  display: flex;
  justify-content: center;
  font-size: 25px;
  font-weight: 400;
  //max-width: 800px;
  @media ${device.mobile} {
    a {
      font-size: 19px;
    }
  }
`

const StyledLink = styled(NavLink)`
  margin-left: 15px;
  margin-right: 15px;

  &.active {
    //background-color: rgb(37,100,94);
    font-weight: 600;
  }
  @media ${device.mobile} {
    margin-left: 5px;
    margin-right: 5px;
    }
  
`;


const Menu = () => {
    return (
        <Fragment>
            <MenuContainer>
                <StyledLink to="/catalog">Каталог</StyledLink>
                <StyledLink to="/services">Услуги</StyledLink>
                <StyledLink to="/delivery">Доставка</StyledLink>
                <StyledLink to="/contacts">Контакты</StyledLink>
            </MenuContainer>
            <hr/>
        </Fragment>
    );
}

export default Menu;